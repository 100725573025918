import { addPropertyControls, ControlType } from "framer"
import CoreContent from "https://framer.com/m/Core-Content-NokC.js@d9MQne1MuJ5UpEKQF0v7"
import { useState, useEffect } from "react"

const autoCloseTags = [
    "area",
    "base",
    "br",
    "col",
    "embed",
    "hr",
    "img",
    "input",
    "link",
    "meta",
    "source",
    "track",
    "wbr",
]
const allowedTags = [
    "a",
    "p",
    "strong",
    "div",
    "b",
    "i",
    "strong",
    "em",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    ...autoCloseTags,
]
function formatAndFixHTML(html) {
    const stack = []
    const result = []
    const tagRegex = /<\/?([a-zA-Z]+)[^>]*>/g
    let match
    let lastIndex = 0

    // Função auxiliar para adicionar indentação no HTML formatado
    function addIndent(level) {
        return "  ".repeat(level)
    }

    // Função auxiliar para fechar todas as tags pendentes
    function closeAllOpenedTags() {
        while (stack.length > 0) {
            const tag = stack.pop()
            result.push(`${addIndent(stack.length)}</${tag}>`)
        }
    }

    // Percorre todas as tags no HTML
    while ((match = tagRegex.exec(html)) !== null) {
        const tag = match[1]
        const isClosingTag = match[0][1] === "/"

        // Adiciona o conteúdo entre as tags ao resultado formatado
        result.push(html.slice(lastIndex, match.index))
        lastIndex = tagRegex.lastIndex

        if (!isClosingTag) {
            // Se for uma tag auto-fechada, apenas adiciona
            if (autoCloseTags.includes(tag)) {
                result.push(`${addIndent(stack.length)}<${tag} />`)
            } else {
                // Se for uma tag de abertura, adiciona ao stack
                result.push(`${addIndent(stack.length)}<${tag}>`)
                stack.push(tag)
            }
        } else {
            // Se for uma tag de fechamento
            if (stack.length === 0 || stack[stack.length - 1] !== tag) {
                // Fecha todas as tags até encontrar a correspondente
                while (stack.length > 0 && stack[stack.length - 1] !== tag) {
                    const unclosedTag = stack.pop()
                    result.push(`${addIndent(stack.length)}</${unclosedTag}>`)
                }
            }
            // Remove a tag correspondente do stack e adiciona a tag de fechamento
            if (stack.length > 0) {
                stack.pop()
                result.push(`${addIndent(stack.length)}</${tag}>`)
            }
        }
    }

    // Adiciona o restante do conteúdo
    result.push(html.slice(lastIndex))

    // Fecha todas as tags pendentes
    closeAllOpenedTags()

    // Retorna o HTML formatado e corrigido
    return result.join("\n").trim()
}

function cleanHTML(html) {
    // Remover <p> aninhados
    html = html.replace(/<p>(\s*<p>.*<\/p>\s*)<\/p>/g, "$1")

    // Remover links aninhados
    html = html.replace(/<a[^>]*>(.*?)<\/a>(\s*<a[^>]*>(.*?)<\/a>)/g, "$1 $3")

    return html
}

// /**
//  *
//  * @framerSupportedLayoutWidth any-prefer-fixed
//  * @framerSupportedLayoutHeight any-prefer-fixed
//  */
// export default function HtmlContentComplex(props) {
//     const { content, ...rest } = props

//     let cleanedHtml = formatAndFixHTML(content).replace(
//         /<a(?![^>]*href=)([^>]*)>/g,
//         '<a href="#"$1>'
//     )

//     // Cria uma expressão regular para encontrar tags que NÃO estão na lista permitida
//     let regex = new RegExp(
//         `<\/?(?!(${allowedTags.join("|")})(\\s|>|\\/))[^>]+>`,
//         "g"
//     )

//     cleanedHtml = cleanedHtml.replace(regex, "")

//     return <CoreContent content={cleanHTML(cleanedHtml)} {...rest} />
// }

function removeHTML(html) {
    // Usando uma expressão regular para remover todas as tags HTML
    return "<div>" + html.replace(/<\/?[^>]+(>|$)/g, "") + "</div>"
}

/**
 *
 * @framerSupportedLayoutWidth any-prefer-fixed
 * @framerSupportedLayoutHeight any-prefer-fixed
 */
export default function HtmlContent(props) {
    const { content, ...rest } = props
    const [html, setHTML] = useState(removeHTML(content))

    useEffect(() => {
        setHTML(content)
    }, [])

    return <CoreContent content={html} {...rest} />
}

HtmlContent.defaultProps = {
    content: "<p>Conteúdo padrão de exemplo.</p>", // Conteúdo padrão como HTML
}

addPropertyControls(HtmlContent, {
    content: {
        type: ControlType.String,
        title: "Content",
        defaultValue: "<p>Conteúdo padrão de exemplo.</p>",
        displayTextArea: true, // Para facilitar a edição de blocos maiores de HTML
    },
})
